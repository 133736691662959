export default {
  "Appointments": {
    "AppointmentInfo": {
      "OPD_Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD Booking"])},
      "Package_Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Booking"])},
      "Speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciality"])},
      "Package_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Name"])},
      "Doctor_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor Name"])},
      "Select_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Date"])},
      "Save_&_Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save & Create"])},
      "Request_Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Payment"])},
      "Reference_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Number"])},
      "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "extend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extend"])}
    },
    "PatientInfo": {
      "Patient_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient Name"])},
      "Patient_Date_of_Birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient Date of Birth"])},
      "Patient_Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient Phone"])},
      "State/Township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State/Township"])},
      "Token_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Type"])},
      "Reserved_Token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved Token"])},
      "Default_Token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Token"])},
      "Assign_Reserved_Token_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Reserved Token Number"])},
      "Assign_Default_Token_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Default Token Number"])},
      "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
      "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
      "Others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])},
      "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remark"])},
      "Booking_Channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Channel"])},
      "Booking_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking ID"])},
      "Select_Slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Slots"])},
      "Reason_for_Reschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for Reschedule"])},
      "Additional_Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Comments"])},
      "Select_Token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Token"])},
      "Booking_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Type"])}
    },
    "OPDMessages": {
      "No_Doctor_available_for_selected_Speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Doctor available for selected Speciality."])},
      "Slots_not_available_for_Selected_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slots not available for Selected Doctor."])},
      "No_Slots_Available_Please_Select_another_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Slots Available. Please Select another date."])}
    },
    "PackageMessages": {
      "No_Packages_available_right_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Packages available right now."])},
      "No_Dates_available_for_this_Package_right_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Dates available for this Package right now."])},
      "No_Slots_available_for_this_selected_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Slots available for this selected date."])}
    },
    "Alert": {
      "Something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
      "No_Packages_Available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Packages Available"])},
      "Error_getting_Packages_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error getting Packages list"])},
      "OPD_Appointment_Created_Successfully_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD Appointment Created Successfully for"])},
      "Error_Creating_Appointment!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Creating Appointment!"])},
      "Package_Appointment_Created_Successfully_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Appointment Created Successfully for"])},
      "Error_creating_package_appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error creating package appointment"])},
      "Error_fetching_Appointment_Details_Try_after_sometime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error fetching Appointment Details. Try after sometime"])},
      "Reschedule_slot_cannot_be_same_as_Current_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reschedule slot cannot be same as Current slot"])},
      "Appointment_Rescheduled_Successfully_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment Rescheduled Successfully for"])},
      "Error_Creating_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Creating Appointment!"])},
      "Error_fetching_Package_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error fetching Package Details"])},
      "Patient_No_Show_Updated_Sccessfully_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient No Show Updated Sccessfully for"])}
    },
    "RescheduleAppointment": {
      "Reschedule_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reschedule Appointment"])},
      "Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment"])},
      "Reschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reschedule"])},
      "Attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention! "])},
      "You_are_now_rescheduling_appointment_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are now rescheduling appointment for"])},
      "Current_Appointment:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Appointment:"])},
      "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for"])},
      "Select_Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Reason"])},
      "Enter_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter comments"])},
      "Reschedule_Appointment_OLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reschedule Appointment OLD"])},
      "Slect_Appointment_Reschedule_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slect Appointment Reschedule Date"])}
    },
    "AppointmentList": {
      "Appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointments"])},
      "Upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming"])},
      "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
      "OPD_Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD Booking"])},
      "Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
      "Search_by_ID_Doctor_Patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by ID,Doctor,Patient"])},
      "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
      "medical_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["medical check"])},
      "Booking_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking ID:"])},
      "Token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
      "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "Regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGULAR"])},
      "Booked_For": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booked For"])},
      "Patient_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient Name"])},
      "Age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
      "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "Patient_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient Type"])},
      "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
      "Existing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing"])},
      "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "Reset_All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset All"])},
      "Apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
      "Error_in_fetching_all_slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in fetching all slots"])},
      "Attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention! "])},
      "Appointment_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment of"])},
      "Appointment_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment Status"])},
      "Phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "Booking_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Type"])},
      "Booking_Channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Channel"])},
      "TownShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TownShip"])},
      "Additional_Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Remarks"])},
      "Confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
      "Arrived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrived"])},
      "No_Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Show"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelled"])},
      "seeing_doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seeing doctor"])},
      "Reschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reschedule"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
      "not_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not reached"])},
      "change_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change token"])},
      "Cancel_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Appointment"])},
      "reason_for_cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for Cancellation"])},
      "You_are_now_cancelling_appointment_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are now cancelling appointment for"])},
      "reference_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference number"])},
      "mode_of_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode of Payment"])},
      "Extended_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extended Date"])},
      "reason_for_noShow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for No-Show"])},
      "confirm_noShow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm No-Show"])},
      "No_Show_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No-Show Appointment"])},
      "Extend_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extend Appointment"])},
      "Current_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Slot"])},
      "yes_Extend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Extend"])},
      "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
      "Appointment_Cancelled_Successfully_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment Cancelled Successfully for"])},
      "Error_in_Cancelling_Appointment!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in Cancelling Appointment!"])},
      "View_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Appointment"])},
      "checked_In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checked In"])},
      "Completed_Package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed Package"])},
      "Booking_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Date"])},
      "Test_Included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Included"])},
      "Print_Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print Out"])},
      "Generating_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generating pdf..."])},
      "Check_In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "Recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended"])},
      "Booked_Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booked Price"])},
      "Current_Booking_Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Booking Price"])},
      "View_package_appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View package appointment"])}
    },
    "CreateAppointment": {
      "Create_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Appointment"])},
      "EnterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter name"])},
      "EnterPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter phone"])},
      "EnterRemarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter remarks"])},
      "Select_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select payment method"])},
      "New_Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Booking"])},
      "Amount_To_Be_Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to be paid"])}
    },
    "CancelAppointment": {
      "Cancel_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Appointment"])},
      "Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment"])},
      "Attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
      "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "You_are_now_cancelling_appointment_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are now cancelling appointment for"])},
      "Booking_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Type"])},
      "Booking_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking ID"])},
      "Reason_for_Cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for Cancellation"])},
      "Additional_Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Comments"])}
    },
    "CreatePackageAppointment": {
      "Create_Package_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Package Appointment"])},
      "Request_Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Payment"])},
      "Select_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select payment method"])},
      "Reference_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Number"])},
      "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "New_Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Booking"])},
      "Package_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package name"])},
      "Package_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package ID"])},
      "Select_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Date"])},
      "No_Dates_available_for_this_Package_right_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Dates available for this Package right now."])},
      "No_Slots_available_for_this_selected_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Slots available for this selected date."])}
    }
  },
  "Timetable": {
    "Heading": {
      "OPD_Timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD Timetable"])},
      "Booking_TimeTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking TimeTable"])},
      "OPD_Operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD Operation"])},
      "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])}
    },
    "DoctorsList": {
      "Doctors_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctors List"])},
      "Select_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select date"])},
      "Custom_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom date"])},
      "Today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
      "Tomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomorrow"])},
      "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "No_Record_Found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Record Found"])},
      "Search_by_doctor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by doctor name"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "No_Of_bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Of bookings"])},
      "Patients_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients list"])},
      "Search_by_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name"])},
      "ANNOUNCEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANNOUNCEMENTS"])},
      "PATIENTS_PRINT_OUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PATIENTS PRINT OUT"])},
      "Generating_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generating PDF"])},
      "Patient_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient name"])},
      "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "Token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
      "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "YET_TO_ARRIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YET TO ARRIVE"])},
      "DOCTOR_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOCTOR IN"])},
      "DOCTOR_OUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOCTOR OUT"])},
      "N/A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "This_option_should_be_performed_only_if_the_doctor_arrives_Is_the_doctor_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This option should be performed only if the doctor arrives, Is the doctor available?"])},
      "Yes_Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Continue"])},
      "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
    },
    "Patient": {
      "NOT_REACHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOT REACHED"])},
      "ARRIVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARRIVED"])},
      "REACHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REACHED"])},
      "NO_SHOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO SHOW"])},
      "SEEING_DOCTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEEING DOCTOR"])},
      "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPLETED"])},
      "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCELLED"])},
      "RESCHEDULED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESCHEDULED"])},
      "RESCHEDULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESCHEDULE"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCEL"])},
      "CHANGE_TOKEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHANGE TOKEN"])}
    },
    "PackagesList": {
      "Packages_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages list"])},
      "Search_by_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by package"])},
      "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "Expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
      "Patients_List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients List"])},
      "Search_by_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name"])},
      "PATIENTS_PRINT_OUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PATIENTS PRINT OUT"])},
      "Patient_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient name"])},
      "Booking_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking ID"])},
      "Booking_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Date"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
    },
    "ChangeToken": {
      "Change_Token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change token"])},
      "Current_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current slot"])},
      "Existing_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing token"])},
      "Patient_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient name"])},
      "Token_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token number"])},
      "No_Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, Cancel"])}
    },
    "Alerts": {
      "Something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
      "Doctor_is_marked_as_out_new_appointments_cannot_be_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor is marked as out, new appointments cannot be created."])},
      "Appointment_Created_Successfully_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment Created Successfully for"])},
      "Error_Creating_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Creating Appointment!"])},
      "Patient_List_Downloaded_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient List Downloaded Successfully"])},
      "Error_while_processing_printout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while processing printout"])},
      "Error_fetching_Appointment_Details_Try_after_sometime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error fetching Appointment Details. Try after sometime"])},
      "Success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
    }
  },
  "NurseList": {
    "Heading": {
      "Add_Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Nurse"])},
      "Edit_Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Nurse"])},
      "Nurse_List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nurse List"])},
      "Save_&_Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save & Create"])},
      "Save_&_Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save & Update"])},
      "Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nurse"])},
      "Search_by_Name_or_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by Name or ID"])}
    },
    "NurseInfo": {
      "Register_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Number"])},
      "Employee_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee ID"])},
      "Nurse_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nurse Name"])},
      "Date_of_Birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
      "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
      "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
      "Others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])},
      "Select_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Type"])},
      "Contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
      "Regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular"])},
      "Contract_End_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract End Date"])},
      "Nurse_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nurse Type"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created_at"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actions"])},
      "Delete_Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Nurse"])},
      "Are_you_sure_you_want_to_delete_the_nurse?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the nurse?"])},
      "Enter_Employee_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Employee ID"])},
      "Enter_Nurse_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Nurse Name"])},
      "DD/MM/YYYY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD/MM/YYYY"])},
      "Enter_Register_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Register Number"])}
    },
    "Alerts": {
      "Nurse_Assigned_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nurse Assigned Successfully"])},
      "No_data_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data found"])},
      "Failed_to_Assign_Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Assign Nurse"])},
      "Nurse_Registered_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nurse Registered Successfully"])},
      "Nurse_Registration_Failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nurse Registration Failed"])},
      "Nurse_Updated_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nurse Updated Successfully"])},
      "No_Data_Found_to_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data Found to update"])},
      "Editing_of_nurse_got_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing of nurse got failed"])},
      "Error_in_fetching_particular_nurse_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in fetching particular nurse details"])},
      "Something_Went_Wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something Went Wrong"])},
      "Error_in_fetching_all_nurse_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in fetching all nurse details"])},
      "EmployeeID_Duplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee ID is Duplicate"])}
    }
  },
  "Accounts": {
    "Heading": {
      "Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
      "Managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
      "Receptionists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receptionist"])},
      "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
      "Add_Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Account"])},
      "Search_By_Account_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search By Account Name"])},
      "Create_New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New"])},
      "New_Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Account"])},
      "Edit_Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Account"])},
      "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])}
    },
    "Table": {
      "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "Account_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Type"])},
      "Accounts_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts Name"])},
      "Phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "Availability_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability Status"])},
      "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "Delete_Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Account"])},
      "Are_you_sure_you_want_to_delete_the_Account?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the Account?"])},
      "No_Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, Cancel"])},
      "Yes_Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Delete"])},
      "Enter_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Name"])}
    },
    "Alerts": {
      "Export_Initiated_You_can_check_this_in_Downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export Initiated You can check this in Downloads"])},
      "Account_has_been_activated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account has been activated successfully"])},
      "Account_has_been_de-activated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account has been de-activated successfully"])},
      "Error_in_fetching_all_acount_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in fetching all acount details"])},
      "Account_has_been_Deleted_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account has been Deleted Successfully"])},
      "Duplicate_Employee_ID_Detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate Employee ID Detected"])},
      "Account_Created_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Created Successfully"])},
      "Account_Updated_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Updated Successfully"])}
    }
  },
  "HomeTileScreen": {
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "Create_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Appointment"])},
    "OPD_Timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD Timetable"])},
    "Appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointments"])},
    "Slot_Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot Settings"])},
    "Assign_Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Nurse"])},
    "Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
    "Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])},
    "Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nurse"])},
    "Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
    "Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
    "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "Downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
    "OPD_Operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD Operations"])},
    "Package_Operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Operations"])},
    "Activity_Logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity logs"])}
  },
  "MainHeader": {
    "Change_Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "Are_you_sure_you_want_to_logout?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to logout?"])},
    "Yes_Sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Sure"])},
    "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
    "Receptionist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receptionist"])},
    "Language_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language settings"])},
    "select_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Language"])},
    "Burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burmese"])}
  },
  "Doctors": {
    "DoctorsList": {
      "Doctors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctors"])},
      "Add_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Doctor"])},
      "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
      "Search_by_DoctorName_or_RegNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by Doctor Name or Reg No."])},
      "Registration_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Number"])},
      "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "Register_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register No"])},
      "Doctor_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor Name"])},
      "Doctor_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor Type"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "Update_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Slot"])},
      "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGULAR"])},
      "CONTRACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTRACT"])},
      "Generate_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Slot"])},
      "Manage_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Slot"])},
      "Generate_OPD_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate OPD Slot"])},
      "Manage_OPD_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage OPD Slot"])},
      "Attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
      "You_are_now_generating_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are now generating for"])},
      "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
      "Generated_slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated slots"])},
      "Select_Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Day"])},
      "Mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
      "Tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
      "Wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
      "Thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
      "Fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
      "Sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
      "Sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])},
      "No_Slots_Available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Slots Available for"])},
      "Select_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Slot"])},
      "Please_Select_a_Day_to_Select_Slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Select a Day to Select Slots"])},
      "Add_Time_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Time Slot"])},
      "Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot"])},
      "Maximum_Booking_Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Booking Count"])},
      "Slot_Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot Time"])},
      "Start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start time"])},
      "End_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End time"])},
      "Add_more_time_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add more time slot"])},
      "Discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard changes"])},
      "Generate_Slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Slots"])},
      "Slot_timings_clash_Please_set_another_Slot_StartTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot timings clash! Please set another Slot StartTime."])},
      "Slot_timings_clash_Please_set_another_Slot_EndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot timings clash! Please set another Slot EndTime."])},
      "Export_Initiated_You_can_check_this_in_Downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export Initiated You can check this in Downloads"])},
      "Doctor_exists_You_may_add_commercial_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor exists. You may add commercial information."])},
      "Please_enable_the_doctor_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enable the doctor first"])},
      "Changes_Discarded_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes Discarded for"])},
      "Please_fill_all_slot_details_before_Generating_Slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill all slot details before Generating Slots!"])},
      "Slots_updated_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slots updated for "])},
      "Slots_Updated_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slots Updated Successfully!"])},
      "Slots_Generated_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slots Generated Successfully!"])},
      "Slots_Updation_Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slots Updation Error!"])},
      "Error_Generating_Slots_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Generating Slots for"])},
      "Doctor_Status_Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor Status Updated"])},
      "Error_updating_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error updating Status"])},
      "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
      "End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])}
    },
    "BasicInformation": {
      "Basic_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Information"])},
      "Doctor_Name_in_English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor Name in English"])},
      "Doctor_Name_in_Burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor Name in Burmese"])},
      "Academic_Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Title"])},
      "Profile_Picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Picture"])},
      "Upload_Profile_Picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Profile Picture"])},
      "Recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended"])},
      "Image_Resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Resolution"])},
      "Upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
      "Speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciality"])},
      "Contact_Phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Phone Number"])},
      "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
      "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
      "Others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])},
      "Service_Start_Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Start Year"])},
      "State_Township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State/Township"])},
      "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])}
    },
    "CommercialInformation": {
      "Commercial_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Information"])},
      "EmployeeID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee ID"])},
      "Select_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Type"])},
      "Contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
      "Regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular"])},
      "Contract_End_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract End Date"])},
      "Reserved_Tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved Tokens"])},
      "Time_Per_Patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Per Patient"])},
      "Doctor_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor Status"])},
      "Enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
      "Disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])}
    },
    "AddDoctor": {
      "Add_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Doctor"])},
      "Doctor_List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor List"])},
      "View_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Doctor"])},
      "Create_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Doctor"])},
      "Doctor_Created_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor Created Successfully"])},
      "Something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
      "ERROR_fetching_Specialities_Data_Try_again_later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERROR fetching Specialities Data. Try again later"])},
      "EmployeeID_Already_Exists_Choose_a_different_EmployeeID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee ID Already Exists! Choose a different Employee ID"])},
      "Something_went_wrong_Check_all_Details_and_try_again_Or_try_after_sometime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Check all Details and try again. Or try after sometime."])}
    },
    "AddRegisteredDoctor": {
      "Add_registered_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add registered Doctor"])},
      "Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])},
      "Add_Existing_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Existing Doctor"])},
      "Register_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Doctor"])}
    },
    "EditDoctor": {
      "Edit_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Doctor"])},
      "Doctor_List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor List"])},
      "Save_&_Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save & Update"])},
      "Registration_Number_Does_Not_Exist_Unable_to_Edit_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Number Does Not Exist! Unable to Edit Doctor."])},
      "Error_Fetching_Dr_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Fetching Dr Details"])},
      "Something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
      "Doctor_Updated_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor Updated Successfully"])},
      "Doctor_Details_Updated_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor Details Updated Successfully"])},
      "EmployeeID_Already_Exists_Choose_a_different_EmployeeID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee ID Already Exists! Choose a different Employee ID"])},
      "Something_went_wrong_Check_all_Details_and_try_again_Or_try_after_sometime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Check all Details and try again. Or try after sometime."])}
    },
    "ViewDoctor": {
      "View_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Doctor"])},
      "Doctor_List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor List"])},
      "Time_per_patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time per patient"])},
      "Reserve_Till": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved Till"])},
      "Mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mins"])},
      "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
      "Generated_Slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated Slots"])},
      "Doctor_Reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor Reviews"])},
      "Coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming soon"])},
      "Overall_Stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overall Stats"])},
      "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGULAR"])},
      "CONTRACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTRACT"])},
      "Years_of_Exp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years of Exp."])},
      "No_of_Patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. of Patients"])},
      "Reviewed_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewed in"])},
      "On": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On"])},
      "Contract_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract End Date"])},
      "Doctor_Slot_is_DeActivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor Slot is de-Activated"])},
      "Error_fetching_Dr_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error fetching Dr Details!"])}
    },
    "Alerts": {
      "Something_went_wrong_Check_all_Details_and_try_again_Or_try_after_sometime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Check all Details and try again. Or try after sometime."])},
      "Employee_ID_Already_Exists_Choose_a_different_Employee_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee ID Already Exists! Choose a different Employee ID"])},
      "Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])},
      "Updated_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated Successfully"])},
      "Error_Fetching_Dr_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Fetching Dr Details"])}
    }
  },
  "HomePage": {
    "OPD_Booking_Stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD Booking Stats"])},
    "Package_Stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Stats"])},
    "Last_30_Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 30 Days"])},
    "Last_7_Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 7 Days"])},
    "Active_Doctors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Doctors"])},
    "Inactive_Doctors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive Doctors"])},
    "Top_Performing_Doctors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Performing Doctors"])},
    "ERROR_while_fetching_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERROR while fetching data"])}
  },
  "Settings": {
    "Availability_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability Status"])},
    "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "Enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
    "Disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
    "OPD_Appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD Appointments"])},
    "OPD_Bookings_are_enabled!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD Bookings are enabled!"])},
    "You_have_enabled_OPD_bookings_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've enabled OPD bookings Successfully."])},
    "OPD_Bookings_are_disabled!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD Bookings are disabled!"])},
    "You_have_disabled_OPD_bookings_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've disabled OPD bookings Successfully."])},
    "Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
    "Packages_Bookings_are_enabled!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Bookings are enabled!"])},
    "You_have_enabled_Packages_bookings_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've enabled Packages bookings Successfully."])},
    "Packages_Bookings_are_disabled!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Bookings are disabled!"])},
    "You_have_disabled_Packages_bookings_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've disabled Packages bookings Successfully."])},
    "Package_Cut-Off_Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Cut-Off Time"])},
    "Enter_Package_Custom_Interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Package Custom Interval"])},
    "Token_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Information"])},
    "Token_Information_is_enabled!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Information is enabled!"])},
    "You_have_enabled_Token_Information_Successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've enabled Token Information Successfully."])},
    "Token_Information_is_disabled!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Information is disabled!"])},
    "You_have_disabled_Token_Information_Successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've disabled Token Information Successfully."])},
    "Cut-Off_Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cut-Off Time"])},
    "The_cut-off_time_feature_allows_for_precise_scheduling_control_optimizing_appointment_availability_based_on_preferences_such_as_24-hour_12-hour_or_custom_time_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cut-off time feature allows for precise scheduling control, optimizing appointment availability based on preferences such as 24-hour, 12-hour, or custom time intervals."])},
    "Opd_Cut-Off_Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opd Cut-Off Time"])},
    "No_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No restriction"])},
    "Custom_Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Time"])},
    "12_Hrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Hrs"])},
    "24_Hrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 Hrs"])},
    "Do_You_Want_to_Save_the_Changes?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do You Want to Save the Changes?"])},
    "No_Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, Cancel"])},
    "Yes_Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Save"])},
    "Changes_Reset!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes Reset!"])},
    "please_fill_the_required_fields!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please fill the required fields!"])},
    "OPERATIONS_DISABLED_CONTACT_MYMEDICINE_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPERATIONS DISABLED - CONTACT MYMEDICINE ADMINs"])},
    "Something_Went_Wrong_Click_OK_to_reload_the_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something Went Wrong Click OK to reload the page"])},
    "Save_the_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save the Changes"])},
    "Enter_Opd_Customer_Interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Opd Customer Interval"])}
  },
  "Reports": {
    "Heading": {
      "Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
      "Report_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Date"])},
      "Start_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
      "End_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])}
    },
    "Table": {
      "Report_Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Title"])},
      "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
    },
    "Alerts": {
      "Export_Initiated_Check_into_download_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export Initiated Check into download section"])}
    }
  },
  "Downloads": {
    "Heading": {
      "Exports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exports"])}
    },
    "Table": {
      "Requested_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested Date"])},
      "File_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Name"])},
      "Page_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Name"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
      "PROCESSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROCESSING"])},
      "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPLETED"])},
      "INITIATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INITIATED"])},
      "REJECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REJECTED"])}
    }
  },
  "SlotSettings": {
    "This_Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Month"])},
    "Next_Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Month"])},
    "Date_range_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date range from"])},
    "Slot_status_updated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot status updated successfully"])},
    "Doctor_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor Name"])},
    "Slot_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot Date"])},
    "Slot_Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot Time"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "Yes_Sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, Sure"])},
    "This_will_cancel_all_the_appointments_and_but_still_keep_the_slot_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will cancel all the appointments and but still keep the slot active."])},
    "This_will_cancel_all_the_appointments_and_disable_the_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will cancel all the appointments and disable the slot"])},
    "Are_you_sure_you_want_to_cancel_all_appoinments_on_this_slot?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel all appoinments on this slot?"])},
    "SOMETHING_WENT_WRONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOMETHING WENT WRONG"])},
    "Slot_Cancelled_succesfully_but_something_went_wrong_while_eanbling_disabling_the_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot Cancelled succesfully, but something went wrong while eanbling/disabling the slot"])},
    "Slot_cancelled_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot cancelled successfully"])},
    "Error_in_fetching_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in fetching data"])},
    "Yes_and_disable_the_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, and disable the slot"])},
    "Yes_and_keep_the_slot_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, and keep the slot active"])},
    "Cancel_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel slot"])}
  },
  "AssignNurseSchedule": {
    "Assign_Schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Schedule"])},
    "Nurse_Assigned_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nurse Assigned Successfully"])},
    "No_data_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data found"])},
    "Failed_to_Assign_Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to Assign Nurse"])},
    "Error_in_fetching_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in fetching data"])},
    "Assign_Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Nurse"])}
  },
  "Packages": {
    "Heading": {
      "Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
      "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "Inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
      "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
      "Create_New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New"])},
      "Search_for_package_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for package name"])},
      "Attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
      "Your_current_package_plan_includes_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your have reached Max active package count of "])},
      "Contact_CE_Team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Contact CE team for further assistance"])},
      "Generate_Package_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Package Slot"])},
      "Manage_Package_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Package Slot"])},
      "Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot"])}
    },
    "Table": {
      "Package_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package name"])},
      "No_of_Test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. of Test"])},
      "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "Slot_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot Details"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "No_of_Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. of Booking"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
    },
    "CreatePackage": {
      "create_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Package"])},
      "package_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package List"])},
      "Add_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add package"])},
      "Basic_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Information"])},
      "Package_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Name"])},
      "Both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both"])},
      "Enter_package_name_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter package name in english"])},
      "Enter_package_name_burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter package name in burmese"])},
      "Select_Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Category"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "Enter_package_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter package price"])},
      "Add_Tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Tags"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "Enter_package_description_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter package description in english"])},
      "Enter_package_description_burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter package description in burmese"])},
      "upload_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Banner"])},
      "recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendation"])},
      "recommendation_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendation in English"])},
      "recommendation_burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendation in Burmese"])},
      "Add_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add more"])},
      "slot_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot Information"])},
      "Max_Slots_For_Package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Slots For Package"])},
      "Applicabe_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicabe for"])},
      "Ages_allowed_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ages Allowed For"])},
      "Ages_allowed_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ages Allowed To"])},
      "package_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Start Date"])},
      "package_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package End Date"])},
      "test_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Details"])},
      "test_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Name"])},
      "name_of_the_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the test"])},
      "Number_of_slots_for_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of slots for package"])},
      "Image_Resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Resolution"])},
      "Upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
      "Recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended"])},
      "Add_tags_for_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add tags for package"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "edit_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Package"])},
      "view_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Package"])},
      "slots_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slots Allowed"])},
      "no_of_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No of Test"])},
      "Test_Include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Include"])},
      "No_of_Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No of Booking"])}
    },
    "Alerts": {
      "Export_Initiated_You_can_check_this_in_Downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export Initiated You can check this in Downloads"])},
      "Failed_to_update_the_package_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to update the package status"])},
      "Operation_failed_unexpectedly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation failed unexpectedly!"])},
      "Insufficient_Package_Permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient Package Permission"])},
      "please_upload_the_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please upload the banner"])},
      "package_created_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["package created successfully"])},
      "failed_to_create_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["failed to create try again"])}
    }
  },
  "Announcements": {
    "Announcement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement"])},
    "Announcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcements"])},
    "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "Review_&_Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review & Submit"])},
    "Phone_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone No"])},
    "Select_Message_Template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Message Template"])},
    "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "Patient_List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient List"])},
    "Select_Patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Patients"])},
    "Token_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token No"])},
    "SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUCCESS"])}
  },
  "ActivityLogs": {
    "More_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Details"])},
    "Date_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date filter"])},
    "Old_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old value"])},
    "New_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New value"])},
    "Field_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
    "Search_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for"])},
    "Hospital_Created_On_(_Date_)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital Created On ( Date )"])},
    "Updated_the_(Hospital_Name)_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated the (Hospital Name) Details"])}
  }
}