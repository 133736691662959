<template>
    <v-app>
        <!-- <router-link to="/">Home</router-link> | -->
        <!-- <router-link to="/about">About</router-link> -->
        <router-view />
	</v-app>
</template>

<style>
#app {
	font-family: Inter, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	width: 100vw;
	background: linear-gradient(135deg, #FFDDDE -0.78%, #CFECFF 99.22%);
}
body {
	margin: 0px !important;
}
nav {
	padding: 30px;
}

nav a {
	font-weight: bold;
	color: #2c3e50;
}

nav a.router-link-exact-active {
	color: #42b983;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #d3d3d3;
}

::-webkit-scrollbar {
  width: 12px;
}
</style>