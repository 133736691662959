export default {
  "Appointments": {
    "AppointmentInfo": {
      "OPD_Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD ရက်ချိန်းယူမည်"])},
      "Package_Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package ရက်ချိန်းယူမည်"])},
      "Speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အထူးကုရွေးရန်"])},
      "Package_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package အမျိုးအစား"])},
      "Doctor_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်ရွေးရန်"])},
      "Select_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်စွဲရွေးရန်"])},
      "Save_&_Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သိမ်းမည်"])},
      "Request_Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ငွေပေးချေရန်"])},
      "Reference_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အမှတ်စဉ်"])},
      "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တင်သွင်းမည်"])},
      "extend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ထပ်တိုးသည်"])}
    },
    "PatientInfo": {
      "Patient_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာအမည်"])},
      "Patient_Date_of_Birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မွေးသက္ကရာဇ်"])},
      "Patient_Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖုန်းနံပါတ်"])},
      "State/Township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြည်နယ်/မြို့နယ်"])},
      "Token_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တိုကင်အမျိုးအစား"])},
      "Reserved_Token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဦးစားပေးတိုကင်"])},
      "Default_Token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပုံမှန်တိုကင်"])},
      "Assign_Reserved_Token_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဦးစားပေးတိုကင်ထည့်မည်"])},
      "Assign_Default_Token_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပုံမှန်တိုကင်ထည့်မည်"])},
      "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျား/မ"])},
      "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျား"])},
      "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မ"])},
      "Others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခြား"])},
      "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှတ်ချက်"])},
      "Booking_Channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းယူသောချန်နယ်"])},
      "Booking_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တိုကင်နံပါတ်"])},
      "Select_Slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ရွေးပါ"])},
      "Reason_for_Reschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိ‌န်ပြောင်းရသည့် အကြောင်းပြချက်"])},
      "Additional_Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ထပ်တိုးမှတ်ချက်"])},
      "Select_Token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တိုကင်ရွေးချယ်မည်"])},
      "Booking_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းအမျိုးအစား"])}
    },
    "OPDMessages": {
      "No_Doctor_available_for_selected_Speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရွေးချယ်ထားသော အထူးကုအတွက် ဆရာဝန်မရှိပါ"])},
      "Slots_not_available_for_Selected_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရွေးချယ်ထားသောဆရာဝန်အတွက် အချိန်စာရင်းမရှိပါ"])},
      "No_Slots_Available_Please_Select_another_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်စာရင်းမရှိပါ၊ ကျေးဇူးပြု၍ အခြားရက်ကိုရွေးချယ်ပါ"])}
    },
    "PackageMessages": {
      "No_Packages_available_right_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လတ်တလောတွင် ပက်ကေ့ချ်မရှိသေးပါ"])},
      "No_Dates_available_for_this_Package_right_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရွေးချယ်ထားသောပက်ကေ့ချ်အတွက် ရက်ချိန်းမရှိသေးပါ"])},
      "No_Slots_available_for_this_selected_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရွေးချယ်ထားသောရက်အတွက် အချိန်စာရင်းမရှိသေးပါ"])}
    },
    "Alert": {
      "Something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချက်အလက် မှားယွင်းနေပါသည်"])},
      "No_Packages_Available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package မရှိသေးပါ"])},
      "Error_getting_Packages_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package စာရင်းမှားယွင်းနေပါသည်"])},
      "OPD_Appointment_Created_Successfully_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆေးခန်းပြရန် ရက်ချိန်းယူခြင်းအောင်မြင်ပါသည်"])},
      "Error_Creating_Appointment!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှားယွင်းနေပါသည်"])},
      "Package_Appointment_Created_Successfully_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package ရက်ချိန်းယူခြင်းအောင်မြင်ပါသည်"])},
      "Error_creating_package_appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှားယွင်းနေပါသည်"])},
      "Error_fetching_Appointment_Details_Try_after_sometime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချက်အလက်များမရယူနိုင်ပါ၊ ထပ်မံကြိုးစားပါ၊"])},
      "Reschedule_slot_cannot_be_same_as_Current_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြောင်းလဲထားသောအချိန်ဇယားသည် ယခုအချိန်ဇယားနှင့်မတူညီနိုင်ပါ"])},
      "Appointment_Rescheduled_Successfully_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အတွက် ရက်ချိန်းပြောင်းခြင်း အောင်မြင်ပါသည်"])},
      "Error_Creating_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းပြောင်း၍မရပါ"])},
      "Error_fetching_Package_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တစ်ခုခုမှားယွင်းနေပါသည်"])},
      "Patient_No_Show_Updated_Sccessfully_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အတွက် လူနာမလာခြင်းကိုအောင်မြင်စွာပြုပြုင်ပြီးပါပြီ"])}
    },
    "RescheduleAppointment": {
      "Reschedule_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းအချိ‌န်ရွေ့ပါ"])},
      "Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်း"])},
      "Reschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိ‌န်ရွေ့ပါ"])},
      "Attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သတိ!"])},
      "You_are_now_rescheduling_appointment_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ယခု သင်ရဲ့ရက်ချိန်းကို အချိန်ရွေ့လိုက်ပါပြီ"])},
      "Current_Appointment:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ယခုရက်ချိန်း"])},
      "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အတွက်"])},
      "Select_Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကြောင်းပြချက်ရွေးပါ "])},
      "Enter_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှတ်ချက်ရေးပါ"])},
      "Reschedule_Appointment_OLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းအဟောင်းကို ပြောင်းလဲမည်"])},
      "Slect_Appointment_Reschedule_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြောင်းလဲလိုသည့်ရက်ကိုရွေးပါ"])}
    },
    "AppointmentList": {
      "Appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းများ"])},
      "Upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လာမည့်ရက်ချိန်းများ"])},
      "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြီးဆုံးရက်ချိန်းများ"])},
      "OPD_Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD ရက်ချိန်းယူမည်"])},
      "Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
      "Search_by_ID_Doctor_Patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID/ဆရာဝန်အမည်/ လူနာအမည်ဖြင့် ရှာပါ"])},
      "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စစ်ရန်"])},
      "medical_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆေးစစ်ချက်"])},
      "Booking_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းနံပါတ်:"])},
      "Token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တိုကင်"])},
      "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်စွဲ"])},
      "Booked_For": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အတွက် ရက်ချိန်းယူပြီးပါပြီ"])},
      "Patient_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာအမည်"])},
      "Age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသက်"])},
      "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျား/ မ"])},
      "Patient_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာအမျိုးအစား"])},
      "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသစ်"])},
      "Existing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အဟောင်း"])},
      "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လုပ်ဆောင်ချက်"])},
      "Reset_All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အားလုံးကို ပြန်လည်သတ်မှတ်ပါ"])},
      "Apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြုလုပ်သည်"])},
      "Error_in_fetching_all_slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းများတွင် အမှားအယွင်းရှိနေပါသည်"])},
      "Attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သတိပြုရန်! "])},
      "Appointment_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["၏ရက်ချိန်း"])},
      "Appointment_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းအခြေအနေ"])},
      "Phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖုန်းနံပါတ်"])},
      "Booking_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းအမျိုးအစား"])},
      "Booking_Channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းယူသောချန်နယ်"])},
      "TownShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မြို့နယ်"])},
      "Additional_Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှတ်ချက်"])},
      "Confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အတည်ပြုပြီးပါပြီ"])},
      "Arrived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရောက်ပါပြီ"])},
      "No_Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မလာ‌ပါ"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပယ်ဖျက်ပြီးပါပြီ"])},
      "seeing_doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်ကြည့် နေပါသည်"])},
      "Reschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိ‌န်ပြောင်းမည်"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပယ်ဖျက်သည်"])},
      "not_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မရောက်သေးပါ"])},
      "change_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တိုကင်နံပါတ်ပြောင်းပါ"])},
      "Cancel_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းကို ပယ်ဖျက်ပါ"])},
      "reason_for_cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပယ်ဖျက်ရသည့် အကြောင်းအရင်း"])},
      "You_are_now_cancelling_appointment_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်က ရက်ချိန်းအတွက် ယခုပယ်ဖျက်ပြီးပါပြီ"])},
      "reference_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အမှတ်စဉ်"])},
      "mode_of_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ငွေပေးချေမှုပုံစံ"])},
      "Extended_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တိုးချဲ့ရက်စွဲ"])},
      "reason_for_noShow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မလာသည့်အကြောင်းအရင်း"])},
      "confirm_noShow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မလာတာသေချာပါသလား"])},
      "No_Show_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မလာသည့်ရက်ချိန်းများ"])},
      "Extend_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းထပ်တိုး"])},
      "Current_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လက်ရှိအချိန်"])},
      "yes_Extend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဟုတ်ကဲ့၊ တိုးချဲ့ပါ။"])},
      "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာ"])},
      "Appointment_Cancelled_Successfully_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အတွက် ရက်ချိန်းကိုပယ်ဖျက်ခြင်း အောင်မြင်ပါသည်"])},
      "Error_in_Cancelling_Appointment!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းကိုပယ်ဖျက်ရာမှာ တစ်ခုခုမှားယွင်းနေပါသည်!"])},
      "View_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းကြည့်ပါ"])},
      "checked_In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဝင်ရောက်ခဲ့သည်"])},
      "Completed_Package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်အပြည့်အစုံ"])},
      "Booking_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းနေ့စွဲ"])},
      "Test_Included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပါဝင်စမ်းသပ်ပါ"])},
      "Print_Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပရင့်ထုတ်မည်"])},
      "Generating_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDFပြောင်းမည်..."])},
      "Check_In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရောက်ရှိကြောင်းစာရင်းသွင်းခြင်း"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကြောင်းအရာ"])},
      "Recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကြံပြုထားသော"])},
      "Booked_Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းယူထားသောစျေးနှုန်း"])},
      "Current_Booking_Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လက်ရှိရက်ချိန်းယူရမည့်စျေးနှုန်း"])},
      "OPD_Timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD အချိန်ဇယား"])},
      "view_package_appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်ရက်ချိန်းကိုကြည့်ပါ"])}
    },
    "CreateAppointment": {
      "Create_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းယူမည်"])},
      "EnterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နာမည်ထည့်ပါ"])},
      "EnterPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖုန်းနံပါတ်ထည့်ပါ"])},
      "EnterRemarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှတ်ချက်ထည့်ပါ"])},
      "Select_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ငွေးပေ:ချေမည့်နည်းလမ်း‌‌ရွေးချယ်ပါ"])},
      "New_Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းအသစ်"])},
      "Amount_To_Be_Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပေးချေရမည့်ငွေပမာဏ"])}
    },
    "CancelAppointment": {
      "Cancel_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းကို ပယ်ဖျက်ပါ"])},
      "Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်း"])},
      "Attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သတိ!"])},
      "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပယ်ဖျက်သည်"])},
      "You_are_now_cancelling_appointment_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are now cancelling appointment for"])},
      "Booking_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းအမျိုးအစား"])},
      "Booking_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းနံပါတ်"])},
      "Reason_for_Cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပယ်ဖျက်ရသည့် အကြောင်းအရင်း"])},
      "Additional_Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ထပ်တိုးမှတ်ချက်"])}
    },
    "CreatePackageAppointment": {
      "Create_Package_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်ရက်ချိန်းကိုဖန်တီးပါ"])},
      "Request_Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ငွေပေးချေရန်"])},
      "Select_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ငွေးပေ:ချေမည့်နည်းလမ်း‌‌ရွေးချယ်ပါ"])},
      "Reference_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အမှတ်စဉ်"])},
      "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တင်သွင်းမည်"])},
      "New_Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းအသစ်"])},
      "Package_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package အမျိုးအစား"])},
      "Package_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်နံပါတ်"])},
      "Select_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်စွဲရွေးရန်"])},
      "No_Dates_available_for_this_Package_right_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရွေးချယ်ထားသောပက်ကေ့ချ်အတွက် ရက်ချိန်းမရှိသေးပါ"])},
      "No_Slots_available_for_this_selected_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရွေးချယ်ထားသောရက်အတွက် အချိန်စာရင်းမရှိသေးပါ"])}
    }
  },
  "Timetable": {
    "Heading": {
      "OPD_Timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD အချိန်ဇယား"])},
      "Booking_TimeTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်း အချိန်ဇယား"])},
      "OPD_Operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD လည်ပတ်မှု"])},
      "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသစ်ဖန်တီးပါ"])}
    },
    "DoctorsList": {
      "Doctors_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်စာရင်း"])},
      "Select_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက် ရွေးချယ်ပါ"])},
      "Custom_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စိတ်ကြိုက်ရက်စွဲ"])},
      "Today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ယနေ့"])},
      "Tomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မနက်ဖြန်"])},
      "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နေ့စွဲ"])},
      "No_Record_Found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှတ်တမ်းမတွေ့ပါ။"])},
      "Search_by_doctor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်အမည်ဖြင့်ရှာပါ"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခြေအနေ"])},
      "No_Of_bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်း အရေအတွက်"])},
      "Patients_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာစာရင်း"])},
      "Search_by_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နာမည်ဖြင့်ရှာပါ"])},
      "ANNOUNCEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကြေငြာချက်များ"])},
      "PATIENTS_PRINT_OUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာစာရင်းပရင့်ထုတ်မည်"])},
      "Generating_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDFပြောင်းမည်"])},
      "Patient_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာအမည်"])},
      "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖုန်းနံပါတ်"])},
      "Token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တိုကင်"])},
      "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လုပ်ဆောင်ချက်"])},
      "YET_TO_ARRIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မရောက်သေးပါ"])},
      "DOCTOR_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်ရောက်ပါပြီ"])},
      "DOCTOR_OUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်ပြန်သွားပါပြီ"])},
      "N/A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မသက်ဆိုင်ပါ"])},
      "This_option_should_be_performed_only_if_the_doctor_arrives_Is_the_doctor_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်ရောက်ချိန်တွင် ဖွင့်ရန်ဖြစ်ပါသည်၊ ယခု ဆရာဝန်ရောက်ပါပြီလား?"])},
      "Yes_Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆက်သွားမည်"])},
      "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မလုပ်တော့ပါ"])}
    },
    "Patient": {
      "NOT_REACHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မရောက်သေးသော"])},
      "ARRIVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရောက်ရှိပြီးသော"])},
      "REACHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရောက်ပါပြီ"])},
      "NO_SHOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မလာ‌ပါ"])},
      "SEEING_DOCTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်နှင့်တွေ့နေပါသည်"])},
      "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆွေးနွေးပြီးသော"])},
      "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပယ်ဖျက်ထားသော"])},
      "RESCHEDULED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ပြောင်းထားသော"])},
      "RESCHEDULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ပြောင်းမည်"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပယ်ဖျက်သည်"])},
      "CHANGE_TOKEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တိုကင်နံပါတ်ပြောင်းပါ"])}
    },
    "PackagesList": {
      "Packages_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်စာရင်း"])},
      "Search_by_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်ဖြင့်ရှာပါ"])},
      "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖွင့်သည်"])},
      "Expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကုန်ဆုံးသွားပါပြီ"])},
      "Patients_List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာစာရင်း"])},
      "Search_by_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နာမည်ဖြင့်ရှာပါ"])},
      "PATIENTS_PRINT_OUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာစာရင်းပရင့်ထုတ်မည်"])},
      "Patient_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာအမည်"])},
      "Booking_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းနံပါတ်"])},
      "Booking_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းနေ့စွဲ"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခြေအနေ"])},
      "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လုပ်ဆောင်ချက်"])}
    },
    "ChangeToken": {
      "Change_Token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တိုကင်နံပါတ်ပြောင်းပါ"])},
      "Current_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လက်ရှိအချိန်"])},
      "Existing_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ယခုတိုကင်"])},
      "Patient_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာအမည်"])},
      "Token_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တိုကင်နံပါတ်"])},
      "No_Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မလုပ်တော့ပါ"])}
    },
    "Alerts": {
      "Something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တစ်ခုခုမှားယွင်းနေပါသည်"])},
      "Doctor_is_marked_as_out_new_appointments_cannot_be_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်၏ သတ်မှတ်ရက်ချိန်းကျော်လွန်သွားပါပြီ ရက်ချိန်းအသစ်များကို ဖန်တီး၍မရပါ."])},
      "Appointment_Created_Successfully_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အတွက် ရက်ချိန်းယူခြင်းအောင်မြင်ပါသည်"])},
      "Error_Creating_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းယူ၍မရပါ!"])},
      "Patient_List_Downloaded_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာစာရင်းကိုထုတ်ယူခြင်းအောင်မြင်ပါသည်"])},
      "Error_while_processing_printout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပရင့်ထုတ်ယူ၍မရပါ"])},
      "Error_fetching_Appointment_Details_Try_after_sometime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချက်အလက်များမရယူနိုင်ပါ၊ ထပ်မံကြိုးစားပါ၊"])},
      "Success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
    }
  },
  "NurseList": {
    "Heading": {
      "Add_Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ထည့်မည်"])},
      "Edit_Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြင်ဆင်မည်"])},
      "Nurse_List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြုစာရင်း"])},
      "Save_&_Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သိမ်းမည်"])},
      "Save_&_Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သိမ်းမည်"])},
      "Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြု"])},
      "Search_by_Name_or_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" အမည် သို့မဟုတ် နံပါတ်ဖြင့်ရှာဖွေပါ"])}
    },
    "NurseInfo": {
      "Register_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြုမှတ်ပုံတင်နံပါတ်"])},
      "Employee_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဝန်ထမ်း နံပါတ်"])},
      "Nurse_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြုအမည်"])},
      "Date_of_Birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မွေးသက္ကရာဇ်"])},
      "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျား/မ"])},
      "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျား"])},
      "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မ"])},
      "Others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခြား"])},
      "Select_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အမျိုးအစားကိုရွေးချယ်ပါ"])},
      "Contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စာချုပ်"])},
      "Regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပုံမှန်"])},
      "Contract_End_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စာချုပ်သက်တမ်းကုန်ဆုံးရက်"])},
      "Nurse_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြုအမျိုးအစား"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖန်တီးခဲ့သည်"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လုပ်ဆောင်ချက်များ"])},
      "Delete_Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖျက်မည်"])},
      "Are_you_sure_you_want_to_delete_the_nurse?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သေချာပါသလား။"])},
      "Enter_Employee_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["၀န်ထမ်း IDထည့်ပါ"])},
      "Enter_Nurse_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြုအမည်ရိုက်ထည့်ပါ"])},
      "DD/MM/YYYY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်/လ/နှစ်"])},
      "Enter_Register_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှတ်ပုံတင်နံပါတ်ထည့်ပါ"])}
    },
    "Alerts": {
      "Nurse_Assigned_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြု တာဝန်ချခြင်းအောင်မြင်ပါသည်"])},
      "No_data_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချက်အလက်ရှာမတွေ့ပါ"])},
      "Failed_to_Assign_Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြု တာဝန်ချခြင်းမအောင်မြင်ပါ"])},
      "Nurse_Registered_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြု စာရင်းသွင်းခြင်းအောင်မြင်ပါသည်"])},
      "Nurse_Registration_Failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြု စာရင်းသွင်းခြင်းမအောင်မြင်ပါ"])},
      "Nurse_Updated_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြု စာရင်းမွမ်းမံခြင်းအောင်မြင်ပါသည်"])},
      "No_Data_Found_to_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြင်ဆင်ဖို့ရန်အချက်အလက်ရှာမတွေ့ပါ"])},
      "Editing_of_nurse_got_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြု အချက်အလက်ပြင်ဆင်ခြင်း မအောင်မြင်ပါ"])},
      "Error_in_fetching_particular_nurse_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြုဧ၊် အသေးစိတ်အချက်အလက်များကို ရယူရာတွင် အမှားအယွင်းရှိသည်"])},
      "Something_Went_Wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တစ်ခုခုမှားယွင်းနေပါသည်"])},
      "Error_in_fetching_all_nurse_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှားယွင်းနေပါသည်၊"])},
      "EmployeeID_Duplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဝန်ထမ်းနံပါတ်က ထပ်နေပါသည်"])}
    }
  },
  "Accounts": {
    "Heading": {
      "Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကောင့်များ"])},
      "Managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မန်နေဂျာများ"])},
      "Receptionists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဧည့်ကြိုများ"])},
      "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ထုတ်မည်"])},
      "Add_Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကောင့်ထပ်ထည့်မည်"])},
      "Search_By_Account_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကောင့်နာမည်ဖြင့်ရှာမည်"])},
      "Create_New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသစ်ထည့်မည်"])},
      "New_Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကောင့်သစ်"])},
      "Edit_Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကောင့် ပြင်ဆင်မည်"])},
      "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကောင့်"])}
    },
    "Table": {
      "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နာမည်"])},
      "Account_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကေောင့်အမျိုးအစား"])},
      "Accounts_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကောင့်အမည်"])},
      "Phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖုန်းနံပါတ်"])},
      "Availability_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ရရှိနိုင်မှုအခြေအနေ"])},
      "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လုပ်ဆောင်ချက်များ"])},
      "Delete_Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကောင့်ဖျက်မည်"])},
      "Are_you_sure_you_want_to_delete_the_Account?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖျက်မှာသေချာပါသလား?"])},
      "No_Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မဖျက်တော့ပါ"])},
      "Yes_Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖျက်မည်"])},
      "Enter_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နာမည်ထည့်ပါ"])}
    },
    "Alerts": {
      "Export_Initiated_You_can_check_this_in_Downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဒေါင်းလုပ်ခ်တွင်ကြည့်ရန် လုပ်ဆောင်နေပါသည်"])},
      "Account_has_been_activated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကောင့်ဖွင့်ခြင်းအောင်မြင်ပါသည်"])},
      "Account_has_been_de-activated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကေောင့်ပိတ်ခြင်းအောင်မြင်ပါသည်"])},
      "Error_in_fetching_all_acount_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချက်အလက်များ မရယူနိုင်ပါ"])},
      "Account_has_been_Deleted_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကေောင့်ဖျက်ခြင်းအောင်မြင်ပါသည်"])},
      "Duplicate_Employee_ID_Detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate Employee ID Detected"])},
      "Account_Created_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Created Successfully"])},
      "Account_Updated_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Updated Successfully"])}
    }
  },
  "HomeTileScreen": {
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပင်မစာမျက်နှာ"])},
    "Create_Appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းယူမည်"])},
    "OPD_Timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD အချိန်စာရင်း"])},
    "Appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းများ"])},
    "Slot_Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ဇယားပြုလုပ်မည်"])},
    "Assign_Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြု တာဝန်ချမည်"])},
    "Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
    "Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်"])},
    "Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြု"])},
    "Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကောင့်"])},
    "Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စာရင်း"])},
    "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆက်တင်"])},
    "Downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဒေါင်းလုပ်ခ်"])},
    "OPD_Operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD စီမံမည်"])},
    "Package_Operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package စီမံမည်"])},
    "Activity_Logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လုပ်ဆောင်ချက်မှတ်တမ်း"])}
  },
  "MainHeader": {
    "Change_Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လျို့ဝှက်နံပါတ်ပြောင်းမည်"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ထွက်မည်"])},
    "Are_you_sure_you_want_to_logout?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ထွက်ဖို့သေချာပါသလား"])},
    "Yes_Sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ထွက်မည်"])},
    "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အက်ဒမင်"])},
    "Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မန်နေဂျာ"])},
    "Receptionist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဧည့်ကြို"])},
    "Language_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဘာသာစကား"])},
    "select_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဘာသာစကားရွေးချယ်မည်"])},
    "Burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မြန်မာ"])}
  },
  "Doctors": {
    "DoctorsList": {
      "Doctors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်များ"])},
      "Add_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်ထည့်မည်"])},
      "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ထုတ်မည်"])},
      "Search_by_DoctorName_or_RegNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်အမည် သို့မဟုတ် ဆမအမှတ်ဖြင့်ရှာမည်"])},
      "Registration_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆမအမှတ်"])},
      "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပယ်ဖျက်မည်"])},
      "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆက်သွားမည်"])},
      "Register_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အမှတ်စဉ်"])},
      "Doctor_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်အမည်"])},
      "Doctor_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အမျိုးအစား"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခြေအနေ"])},
      "Update_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်စာရင်းပြုပြင်မည်"])},
      "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လုပ်ဆောင်ချက်"])},
      "REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပုံမှန်"])},
      "CONTRACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စာချုပ်"])},
      "Generate_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်စာရင်းထည့်မည်"])},
      "Manage_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်စာရင်းပြင်ဆင်မည်"])},
      "Generate_OPD_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD အချိန်စာရင်းထည့်မည်"])},
      "Manage_OPD_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD အချိန်စာရင်းပြင်ဆင်မည်"])},
      "Attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သတိပြုရန်!"])},
      "You_are_now_generating_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အတွက် အချိန်စာရင်းထည့်နေပါသည်"])},
      "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နေ့ရက်"])},
      "Generated_slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်စာရင်းထည့်မည်"])},
      "Select_Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ရွေးမည်"])},
      "Mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တနင်္လာ"])},
      "Tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အင်္ဂါ"])},
      "Wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဗုဒ္ဓဟူး"])},
      "Thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကြာသပတေး"])},
      "Fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သောကြာ"])},
      "Sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စနေ"])},
      "Sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တနင်္ဂနွေ"])},
      "No_Slots_Available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အတွက်အချိန်စာရင်းမရှိပါ"])},
      "Select_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်စာရင်းရွေးပါ"])},
      "Please_Select_a_Day_to_Select_Slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်စာရင်းရွေးရန်ရက်ရွေးပါ"])},
      "Add_Time_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ထည့်မည်"])},
      "Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်စာရင်း"])},
      "Maximum_Booking_Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အများဆုံးလက်ခံမည့်တိုကင်အရေအတွက်"])},
      "Slot_Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်စာရင်း"])},
      "Start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စကြည့်မည့်အချိန်"])},
      "End_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြီးဆုံးမည့်အချိန်"])},
      "Add_more_time_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်စာရင်းထပ်ထည့်မည်"])},
      "Discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖျက်မည်"])},
      "Generate_Slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်စာရင်းထည့်မည်"])},
      "Slot_timings_clash_Please_set_another_Slot_StartTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်တိုက်နေသည်! အခြားအချိန်ရွေးချယ်ပါ"])},
      "Slot_timings_clash_Please_set_another_Slot_EndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်တိုက်နေသည်! အခြားအချိန်ရွေးချယ်ပါ"])},
      "Export_Initiated_You_can_check_this_in_Downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဒေါင်းလုပ်ခ်တွင်ကြည့်ရန်တင်ပို့ပါ"])},
      "Doctor_exists_You_may_add_commercial_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်ရှိပြီးဖြစ်ပါသည်။ ဆေးရုံနှင့်ဆိုင်သောအချက်အလက်များ ဖြည့်ပါ"])},
      "Please_enable_the_doctor_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်ရွေးချယ်ပါ"])},
      "Changes_Discarded_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြောင်းလဲခြင်းမလုပ်တော့ပါ"])},
      "Please_fill_all_slot_details_before_Generating_Slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျေးဇူးပြု၍ အချိန်ဇယားများကို ပြည့်စုံစွာ ဖြည့်ပါ!"])},
      "Slots_updated_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ဇယားပြောင်းပါသည်"])},
      "Slots_Updated_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ဇယားပြောင်းခြင်းအောင်မြင်ပါသည်!"])},
      "Slots_Generated_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ဇယားထည့်ခြင်းအောင်မြင်ပါသည်!"])},
      "Slots_Updation_Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ဇယားပြောင်းလို့မရပါ!"])},
      "Error_Generating_Slots_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ဇယားထည့်လို့မရပါ"])},
      "Doctor_Status_Updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန် အခြေအနေကို ပြောင်းထားသည်"])},
      "Error_updating_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန် အခြေအနေကို ပြောင်းလို့မရပါ"])},
      "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start"])},
      "End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])}
    },
    "BasicInformation": {
      "Basic_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခြေခံအချက်အလက်"])},
      "Doctor_Name_in_English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်အမည်(အင်္ဂလိပ်)"])},
      "Doctor_Name_in_Burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်အမည်(မြန်မာ)"])},
      "Academic_Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပညာရပ်ဆိုင်ရာ ဘွဲ့များ"])},
      "Profile_Picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပရိုဖိုင်ပုံ"])},
      "Upload_Profile_Picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပရိုဖိုင်ပုံတင်မည်"])},
      "Recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကြံပြုသည်"])},
      "Image_Resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဓါတ်ပုံအရည်အသွေး"])},
      "Upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တင်မည်"])},
      "Speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အထူးကု"])},
      "Contact_Phone_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖုန်းနံပါတ်"])},
      "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျား/မ"])},
      "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျား"])},
      "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မ"])},
      "Others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခြား"])},
      "Service_Start_Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စတင်တာဝန်ထမ်းဆာင်သည့်နှစ်"])},
      "State_Township": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြည်နယ်/မြို့နယ်"])},
      "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကြောင်းအရာ"])}
    },
    "CommercialInformation": {
      "Commercial_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆေးရုံနှင့်သက်ဆိုင်သည့်အချက်အလက်များ"])},
      "EmployeeID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဝန်ထမ်း နံပါတ်"])},
      "Select_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အမျိုးအစားကိုရွေးချယ်ပါ"])},
      "Contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စာချုပ်"])},
      "Regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပုံမှန်"])},
      "Contract_End_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စာချုပ်သက်တမ်းကုန်ဆုံးရက်"])},
      "Reserved_Tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဦးစားပေးတိုကင်"])},
      "Time_Per_Patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာတစ်ဦးကြည့်ချိန်"])},
      "Doctor_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်ရှိ/မရှိ"])},
      "Enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖွင့်မည်"])},
      "Disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပိတ်မည်"])}
    },
    "AddDoctor": {
      "Add_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်ထည့်မည်"])},
      "Doctor_List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်စာရင်း"])},
      "View_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကြည့်မည်"])},
      "Create_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ထည့်မည်"])},
      "Doctor_Created_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်အသစ် ထည့်သွင်းပြီးပါပြီ"])},
      "Something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တစ်ခုခုမှားယွင်းနေပါသည်"])},
      "ERROR_fetching_Specialities_Data_Try_again_later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တစ်ခုခုမှားယွင်းနေပါသည်  နောက်တစ်ကြိမ်ပြန်လည်ကြိုးစားကြည့်ပါ"])},
      "EmployeeID_Already_Exists_Choose_a_different_EmployeeID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID နံပါတ်ရှိပြီးသားပါ အသစ်တစ်ခုထပ်မံထည့်သွင်းပါ"])},
      "Something_went_wrong_Check_all_Details_and_try_again_Or_try_after_sometime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တစ်ခုခုမှားယွင်းနေပါသည်  နောက်တစ်ကြိမ်ပြန်လည်ကြိုးစားကြည့်ပါ"])}
    },
    "AddRegisteredDoctor": {
      "Add_registered_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှတ်ပုံတင်ထားသောဆရာဝန်ကို အသစ်ထည့်ပါ"])},
      "Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်"])},
      "Add_Existing_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Existing Doctor"])},
      "Register_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှတ်ပုံတင်ထားသောဆရာဝန်"])}
    },
    "EditDoctor": {
      "Edit_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြင်ဆင်မည်"])},
      "Doctor_List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်စာရင်း"])},
      "Save_&_Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သိမ်းမည်"])},
      "Registration_Number_Does_Not_Exist_Unable_to_Edit_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆမနံပါတ်မရှိပါသဖြင့် ပြင်ဆင်လို့မရပါ"])},
      "Error_Fetching_Dr_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်အချက်အလက်များမရယူနိုင်ပါ"])},
      "Something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တစ်ခုခုမှားယွင်းနေပါသည်"])},
      "Doctor_Updated_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်အချက်အလက်များပြောင်းခြင်းအောင်မြင်ပါသည်"])},
      "Doctor_Details_Updated_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်အချက်အလက်များပြောင်းခြင်းအောင်မြင်ပါသည်"])},
      "EmployeeID_Already_Exists_Choose_a_different_EmployeeID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဝန်ထမ်းအမှတ်စဥ်ရှိပြီးဖြစ်ပါသည်၊ နံပါတ်အသစ်ရွေးချယ်ပါ"])},
      "Something_went_wrong_Check_all_Details_and_try_again_Or_try_after_sometime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှားယွင်းနေပါသည်၊ အသေးစိတ်ကိုစစ်ဆေးပြီး ထပ်မံကြိုးစားပါ"])}
    },
    "ViewDoctor": {
      "View_Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်အကြောင်းးအသေးစိတ်ကြည့်ရှုပါ"])},
      "Doctor_List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်စာရင်း"])},
      "Time_per_patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာတစ်ဦးကြည့်ရန်ကြာချိန်"])},
      "Reserve_Till": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved Till"])},
      "Mins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မိနစ်"])},
      "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်စွဲ"])},
      "Generated_Slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ဇယားထည့်မည်"])},
      "Doctor_Reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်အပေါ်သုံးသပ်ချက်များ"])},
      "Coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မကြာမီ"])},
      "Overall_Stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချက်အလက်များ"])},
      "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကြောင်း"])},
      "REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပုံမှန်"])},
      "CONTRACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စာချုပ်"])},
      "Years_of_Exp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အတွေ့အကြုံ(နှစ်)"])},
      "No_of_Patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာအရေအတွက်"])},
      "Reviewed_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တွင် သုံးသပ်ထားသည်"])},
      "On": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အပေါ် "])},
      "Contract_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စာချုပ်သက်တမ်းကုန်ဆုံးရက်"])},
      "Doctor_Slot_is_DeActivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်အချိန်ဇယားကို ပိတ်ထားလိုက်ပါပြီ။"])},
      "Error_fetching_Dr_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှားယွင်းနေပါသည်၊"])}
    },
    "Alerts": {
      "Something_went_wrong_Check_all_Details_and_try_again_Or_try_after_sometime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တစ်ခုခုမှားယွင်းနေပါသည်  နောက်တစ်ကြိမ်ပြန်လည်ကြိုးစားကြည့်ပါ"])},
      "Employee_ID_Already_Exists_Choose_a_different_Employee_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID နံပါတ်ရှိပြီးသားပါ အသစ်တစ်ခုထပ်မံထည့်သွင်းပါ"])},
      "Doctor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])},
      "Updated_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated Successfully"])},
      "Error_Fetching_Dr_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်အချက်အလက်များမရယူနိုင်ပါ"])}
    }
  },
  "HomePage": {
    "OPD_Booking_Stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPD ရက်ချိန်းအချက်အလက်များ"])},
    "Package_Stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package အချက်အလက်များ"])},
    "Last_30_Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လွန်ခဲ့သောရက်၃၀"])},
    "Last_7_Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နောက်ဆုံးတစ်ပတ်"])},
    "Active_Doctors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လက်ရှိဆရာဝန်များ"])},
    "Inactive_Doctors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရပ်နားထားသောဆရာဝန်များ"])},
    "Top_Performing_Doctors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူနာအများဆုံးဆရာဝန်များ"])},
    "ERROR_while_fetching_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချက်အလက်များမရယူနိုင်ပါ"])}
  },
  "Settings": {
    "Availability_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ရရှိနိုင်မှုအခြေအနေ"])},
    "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြန်လည်ပြင်ဆင်မည်"])},
    "Enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖွင့်ပါ"])},
    "Disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပိတ်ပါ"])},
    "OPD_Appointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းများ"])},
    "OPD_Bookings_are_enabled!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းများ ယူလိုပါသလား"])},
    "You_have_enabled_OPD_bookings_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်ရဲ့ ရက်ချိန်းယူခြင်းအောင်မြင်ပါသည်"])},
    "OPD_Bookings_are_disabled!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်ရဲ့ရက်ချိန်းကို ပယ်ဖျက်လိုပါသလား"])},
    "You_have_disabled_OPD_bookings_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်ရဲ့ရက်ချိန်းကို ပယ်ဖျက်ပြီးပါပြီ"])},
    "Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
    "Packages_Bookings_are_enabled!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်ရက်ချိန်းယူလိုပါသလား"])},
    "You_have_enabled_Packages_bookings_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်ရဲ့ ရက်ချိန်းယူခြင်းအောင်မြင်ပါသည်"])},
    "Packages_Bookings_are_disabled!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်ရက်ချိန်းပယ်ဖျက်လိုပါသလား"])},
    "You_have_disabled_Packages_bookings_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်ရက်ချိန်းပယ်ဖျက်ပြီးပါပြီ"])},
    "Package_Cut-Off_Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နောက်ဆုံးရက်ချိန်းယူနိုင်မည့်အချိန်(ပက်ကေ့ချ်)"])},
    "Enter_Package_Custom_Interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သတ်မှတ်လိုသည့်အချိန်ရိုက်ထည့်ပါ"])},
    "Token_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တိုကင်အချက်အလက်"])},
    "Token_Information_is_enabled!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တိုကင်းများ ယူလိုပါသလား"])},
    "You_have_enabled_Token_Information_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်ရဲ့ တိုကင်းယူခြင်းအောင်မြင်ပါသည်"])},
    "Token_Information_is_disabled!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်ရဲ့တိုကင်ကို ပယ်ဖျက်လိုပါသလား"])},
    "You_have_disabled_Token_Information_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်ရဲ့တိုကင်ကို ပယ်ဖျက်ပြီးပါပြီ"])},
    "Cut-Off_Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နောက်ဆုံးရက်ချိန်းယူနိုင်မည့်အချိန်"])},
    "The_cut-off_time_feature_allows_for_precise_scheduling_control_optimizing_appointment_availability_based_on_preferences_such_as_24-hour_12-hour_or_custom_time_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["၂၄ နာရီ၊ ၁၂ နာရီ၊ စိတ်ကြိုက်အချိန် စသဖြင့်နောက်ဆုံးရက်ချိန်းယူနိုင်မည့်အချိန်သတ်မှတ်ခြင်းဖြင့် အချိန်ဇယားများကို တိကျသေချာစွာစီမံနိုင်ပါသည်။"])},
    "Opd_Cut-Off_Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နောက်ဆုံးရက်ချိန်းယူနိုင်မည့်အချိန်(OPD)"])},
    "No_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မကန့်သတ်ထားပါ"])},
    "Custom_Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စိတ်ကြိုက်အချိန်"])},
    "12_Hrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["၁၂ နာရီ"])},
    "24_Hrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["၂၄ နာရီ"])},
    "Do_You_Want_to_Save_the_Changes?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သိမ်းလိုပါသလား"])},
    "No_Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မလုပ်တော့ပါ"])},
    "Yes_Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သိမ်းမည်"])},
    "Changes_Reset!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အပြောင်းအလဲများကို ပြန်လည်သတ်မှတ်ပါ"])},
    "please_fill_the_required_fields!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လိုအပ်သည့်နေရာများကို ဖြည့်စွက်ပါ!"])},
    "OPERATIONS_DISABLED_CONTACT_MYMEDICINE_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လုပ်ဆောင်မှုရပ်တန့်နေပါသည် MYMEDICINE ADMIN ကိုဆက်သွယ်ပါ"])},
    "Something_Went_Wrong_Click_OK_to_reload_the_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တစ်ခုခုမှားယွင်းနေပါသည် OK ကိုနှိပ်ပါ"])},
    "Save_the_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သိမ်းမည်"])},
    "Enter_Opd_Customer_Interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Opd Customer Interval"])}
  },
  "Reports": {
    "Heading": {
      "Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှတ်တမ်းများ"])},
      "Report_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်စွဲ"])},
      "Start_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စတင်မည့်ရက်"])},
      "End_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြီးဆုံးမည့်ရကိ"])}
    },
    "Table": {
      "Report_Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှတ်တမ်းအမျိုးအစား"])},
      "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လုပ်ဆောင်ချက်များ"])},
      "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ထုတ်မည်"])}
    },
    "Alerts": {
      "Export_Initiated_Check_into_download_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဒေါင်းလုပ်ခ်တွင်ကြည့်ရန် လုပ်ဆောင်နေပါသည်"])}
    }
  },
  "Downloads": {
    "Heading": {
      "Exports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ထုတ်မည်"])}
    },
    "Table": {
      "Requested_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ထုတ်မည့်ရက်စွဲ"])},
      "File_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖိုင်အမည်"])},
      "Page_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စာမျက်နှာအမည်"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခြေအနေ"])},
      "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လုပ်ဆောင်မှု"])},
      "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆွဲထုတ်ပါ"])},
      "PROCESSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လုပ်ဆောင်နေသည်"])},
      "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လုပ််ဆောင်မှုအောင်မြင်ပါသည်"])},
      "INITIATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စတင်သည်"])},
      "REJECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ငြင်းပယ်သည်"])}
    }
  },
  "SlotSettings": {
    "This_Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ယခုလ"])},
    "Next_Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နောက်လ"])},
    "Date_range_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်စွဲ အပိုင်းအခြား"])},
    "Doctor_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆရာဝန်အမည်"])},
    "Slot_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်စွဲ"])},
    "Slot_Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခြေအနေ"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လုပ်ဆောင်ချက်"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မလုပ်တော့ပါ"])},
    "Yes_Sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သေချာပါသည်"])},
    "This_will_cancel_all_the_appointments_and_but_still_keep_the_slot_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းအားလုံးပယ်ဖျက်မည် သို့သော်အချိန်ဇယားတွေယခင်အတိုင်းထိန်းသိမ်းထားမည်"])},
    "This_will_cancel_all_the_appointments_and_disable_the_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းအားလုံး နှင့် အချိန်ဇယားများပယ်ဖျက်မည်"])},
    "Are_you_sure_you_want_to_cancel_all_appoinments_on_this_slot?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ဇယားအတွင်းရှိ ရက်ချိန်းအားလုံးပယ်ဖျက်မှာ သေချာပါသလား"])},
    "Slot_status_updated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ဇယားကို အောင်မြင်စွာ ပြင်ဆင်ပြီးပါပြီ"])},
    "SOMETHING_WENT_WRONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တစ်ခုခုမှားယွင်းနေပါသည်"])},
    "Slot_Cancelled_succesfully_but_something_went_wrong_while_eanbling_disabling_the_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ကိုပယ်ဖျက်ပြီးပါပြီ သို့သော်တစ်ခုခုမှားယွင်းနေပါသည်"])},
    "Slot_cancelled_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ကိုပယ်ဖျက်ပြီးပါပြီ"])},
    "Error_in_fetching_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဒေတာရယူရာတွင် အမှားအယွင်းရှိနေပါသည်"])},
    "Yes_and_disable_the_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သေချာပါသည် အချိန်ဇယားများကိုပိတ်ပါမည်"])},
    "Yes_and_keep_the_slot_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သေချာပါသည် သို့သော်အချိန်ဇယားများကိုဆက်ဖွင့်ထားပါမည်"])},
    "Cancel_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ဇယားကို ပယ်ဖျက်မည်"])}
  },
  "AssignNurseSchedule": {
    "Assign_Schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ဇယားထည့်မည်"])},
    "Nurse_Assigned_Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြု တာဝန်ချခြင်းအောင်မြင်ပါသည်"])},
    "No_data_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချက်အလက်ရှာမတွေ့ပါ"])},
    "Failed_to_Assign_Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြု တာဝန်ချခြင်းမအောင်မြင်ပါ"])},
    "Error_in_fetching_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဒေတာရယူရာတွင် အမှားအယွင်းရှိနေပါသည်"])},
    "Assign_Nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သူနာပြု တာဝန်ချမည်"])}
  },
  "Packages": {
    "Heading": {
      "Packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
      "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အားလုံး"])},
      "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖွင့်သည်"])},
      "Inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပိတ်သည်"])},
      "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ထုတ်ပါ"])},
      "Create_New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသစ်ဖန်တီးပါ"])},
      "Search_for_package_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်အမည် ရှာဖွေပါ"])},
      "Attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သတိ!"])},
      "Your_current_package_plan_includes_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်ရဲ့ ယခုပက်ကေ့ချ်တွင် ပါဝင်သော "])},
      "Contact_CE_Team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နောက်ထပ်အကူအညီအတွက် CE အဖွဲ့ကို ဆက်သွယ်ပါ"])},
      "Generate_Package_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်အချိန်ဇယားထည့်မည်"])},
      "Manage_Package_Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်အချိန်ဇယားပြင်ဆင်မည်"])},
      "Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ဇယား"])}
    },
    "Table": {
      "Package_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package အမျိုးအစား"])},
      "No_of_Test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စမ်းသပ်မှုနံပါတ်စဉ်"])},
      "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စေ◌ျးနှုန်း"])},
      "Slot_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ဇယားအသေးစိတ်"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖော်ပြချက်"])},
      "No_of_Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ချိန်းနံပါတ်"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခြေအနေ"])},
      "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လုပ်ဆောင်ချက်"])}
    },
    "CreatePackage": {
      "create_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ် အသစ်ဖန်တီးပါ"])},
      "package_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ် စာရင်း"])},
      "Add_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ် အသစ်ထည့်ပါ"])},
      "Basic_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခြေခံအချက်အလက်"])},
      "Package_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package အမျိုးအစား"])},
      "Both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both"])},
      "Enter_package_name_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်အမည်(အင်္ဂလိပ်)"])},
      "Enter_package_name_burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်အမည်(မြန်မာ)"])},
      "Select_Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရွေးချယ်ပါ"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စျေးနှုန်း"])},
      "Enter_package_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စျေးနှုန်းထည့်မည်"])},
      "Add_Tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tags များထည့်မည်"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကြောင်းအရာ"])},
      "Enter_package_description_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်(အင်္ဂလိပ်)"])},
      "Enter_package_description_burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်(မြန်မာ)"])},
      "upload_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner ပုံထည့်မ်"])},
      "recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကြံပြုချက်"])},
      "recommendation_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကြံပြုချက် (အင်္ဂလိပ်)"])},
      "recommendation_burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကြံပြုချက် (မြန်မာ)"])},
      "Add_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ထပ်ထည့်မ်"])},
      "slot_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အချိန်ဇယားများ"])},
      "Max_Slots_For_Package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အများဆုံးလက်ခံနိုင်မည့်အရေအတွက်"])},
      "Applicabe_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အတွက်သင့်လျော်သည်"])},
      "Ages_allowed_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ခွင့်ပြုထားသောအသက်(မှ)"])},
      "Ages_allowed_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ခွင့်ပြုထားသောအသက်(ထိ)"])},
      "package_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package စတင်မည့်ရက်"])},
      "package_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package ပြီးဆုံးမည့်ရက်စွဲ"])},
      "test_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စမ်းသပ်မှုအသေးစိတ်"])},
      "test_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အမည်"])},
      "name_of_the_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အမည်"])},
      "Number_of_slots_for_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အရေအတွက်"])},
      "Image_Resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဓါတ်ပုံအရည်အသွေး"])},
      "Upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တင်မည်"])},
      "Recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကြံပြုထားသော"])},
      "Add_tags_for_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tags များထည့်မည်"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရွေးချယ်ပါ"])},
      "edit_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Package"])},
      "view_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Package"])},
      "slots_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slots Allowed"])},
      "no_of_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No of Test"])},
      "Test_Include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Include"])},
      "No_of_Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No of Booking"])}
    },
    "Alerts": {
      "Export_Initiated_You_can_check_this_in_Downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဒေါင်းလုပ်ခ်တွင်ကြည့်ရန် လုပ်ဆောင်နေပါသည်"])},
      "Failed_to_update_the_package_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်ကိုအဆင့်မြင့်တင်ခြင်း မအောင်မြင်ပါ"])},
      "please_upload_the_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please upload the banner"])},
      "Operation_failed_unexpectedly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation failed unexpectedly!"])},
      "Insufficient_Package_Permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient Package Permission"])},
      "Package_created_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပက်ကေ့ချ်အသစ်ပြုလုပ်ခြင်း အောင်မြင်ပါသည်"])},
      "failed_to_create_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မအောင်မြင်ပါ အသစ်တစ်ဖန်ပြန်လည်ကြိုးစားပါ"])}
    }
  },
  "Announcements": {
    "Announcement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement (mm)"])},
    "Announcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကြေငြာချက်များ"])},
    "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြန်လည်ပြင်ဆင်မည်"])},
    "Review_&_Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review & Submit"])},
    "Phone_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖုန်းနံပါတ်"])},
    "Select_Message_Template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Message Template"])},
    "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "Patient_List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient List"])},
    "Select_Patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Patients"])},
    "Token_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token No"])},
    "SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUCCESS"])}
  },
  "ActivityLogs": {
    "More_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသေးစိတ်"])},
    "Date_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရက်ရွေးမည်"])},
    "Old_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မပြောင်းခင်"])},
    "New_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြောင်းပြီး"])},
    "Field_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နယ်ပယ်အမည်"])},
    "Search_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရှာရန်"])},
    "Hospital_Created_On_(_Date_)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆေးရုံစားရင်းသွင်းသည့်နေ့"])},
    "Updated_the_(Hospital_Name)_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ဆေးရုံအမည်) အသေးစိတ်ကို ပြင်ဆင်ပြီးပါပြီ"])}
  }
}